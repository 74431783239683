


export const imagesBgsList = [
  {
    id: 4,
    url: "/ai_tools/backgrounds/3VuFEc65rCemIaqll97ISd.webp",
  },
  {
    id: 5,
    url: "/ai_tools/backgrounds/5DpjreDULpthMF0Dd5aRq6.webp",
  },
  {
    id: 6,
    url: "/ai_tools/backgrounds/5uPVGaOp0ehIggt8w2z7Fe.webp",
  },
  {
    id: 7,
    url: "/ai_tools/backgrounds/8SJLlBnTk7EIbKjUJLcvIq.webp",
  },
  {
    id: 8,
    url: "ai_tools/backgrounds/do2q5JeLnAJ4vz3IIMoNmb.webp",
  },
  {
    id: 9,
    url: "/ai_tools/backgrounds/f7DmFP2Ml92yGGGDFGdPOi.webp",
  },
  {
    id: 10,
    url: "/ai_tools/backgrounds/ok8PI8mcafAb94QiMIseSf.webp",
  },
  {
    id: 11,
    url: "/ai_tools/backgrounds/qlhTqCcV3F5wh2AFop2iD0.webp",
  },
  {
    id: 12,
    url: "/ai_tools/backgrounds/rP4OoIow2Jrpu45DdMSkqU.webp",
  },
  {
    id: 13,
    url: "/ai_tools/backgrounds/tikBm2FEjb3sVABtHheRTb.webp",
  },
];


export const colorBgsList = [
  {
    id: 'color_1',
    border: true,
    type: 'color',
    color: '#fff'
  },
  {
    id: 'color_2',
    border: true,
    type: 'color',
    color: '#eee'
  },
  {
    id: 'color_3',
    border: true,
    type: 'color',
    color: '#D3D3D3'
  },
  {
    id: 'color_4',

    type: 'color',
    color: '#cfcfcf'
  },
  {
    id: 'color_5',

    type: 'color',
    color: '#9c9c9c'
  },
  {
    id: 'color_6',

    type: 'color',
    color: '#3b3b3b'
  },
  {
    id: 'color_7',

    type: 'color',
    color: '#cfddff'
  },
  {
    id: 'color_8',

    type: 'color',
    color: '#bad0c4'
  },
  {
    id: 'color_9',

    type: 'color',
    color: '#e2d4f0'
  },
  {
    id: 'color_10',

    type: 'color',
    color: '#fbe2be'
  },
  {
    id: 'color_11',

    type: 'color',
    color: '#9a8e6e'
  },
  {
    id: 'color_12',

    type: 'color',
    color: '#a37960'
  },
];