import { onMounted, onUnmounted } from "vue";

//b 是否是批量
export default function UnloadHandler(b) {
  const beforeUnloadHandler = (e) => {
    if (b) {
      e.preventDefault();
      e.returnValue = "";
    }
  };

  onMounted(() => {
    window.addEventListener("beforeunload", beforeUnloadHandler);
  });

  onUnmounted(() => {
    window.removeEventListener("beforeunload", beforeUnloadHandler);
  });
}
