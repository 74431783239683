<template>
  <div class="matting-modal-container">
    <a-modal v-model:visible="visible" :title="null" @ok="handleOk" @cancel="handleOk" :footer="null" width="840px"
      :maskClosable="false" centered class="matting-container" transitionName="">
      <template #closeIcon>
        <div class="close-icon">
          <img :src="close_icon" :style="{ width: '15.46px', height: '15.46px' }" />
        </div>
      </template>
      <div class="modal-wrap">
        <!-- modal内容 -->
        <div class="bg-replace-modal-container flex">
          <!-- canvas -->
          <div class="modal-content-left" :style="innerContentLeftStyle" v-if="slots['modalLeft']">
            <slot name="modalLeft"></slot>
          </div>
          <!-- background -->
          <div class="modal-content-right" :style="innerContentRightStyle">
            <slot name="modalRight"></slot>
          </div>
        </div>
        <!-- modal底部 -->
        <div class="footer" :style="innerFooterStyle" v-if="slots['footer']">
          <slot name="footer"> </slot>
        </div>

        <div class="footer-box" :style="innerFooterStyle" v-else>
          <div type="default" class="upload-btn" @click="handleOk">cancel</div>
          <div type="default" class="upload-btn" @click="handleConfirm">Done</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, useSlots, watchEffect, defineExpose } from 'vue';
import close_icon from '@/assets/close_icon.svg'
import { eventTracking } from '@/utils/eventTracking.js';
const slots = useSlots();
const emits = defineEmits(['closeModal', 'confirmReplaceBg']);
const props = defineProps({
  footerStyle: Object,
  modalLeftStyle: Object,
  modalRightStyle: Object,
  resultImageData: Object,
  defaultBgImages: Object,
  currentBgItem: Object,
  showCanvasBox: {
    type: Boolean,
    default: true
  },
  isBatch: {
    type: Boolean,
    default: false,
  },
  modalVisible: Boolean
})

const visible = ref(false);
watchEffect(() => {
  if (props.modalVisible) {
    visible.value = props.modalVisible
  }
})

// 默认的left样式
const innerContentLeftStyle = computed(() => {
  const style = {
    width: '454px',
    height: '454px',
    'margin-right': '36px',
  }

  if (props.modalLeftStyle) {
    return {
      ...style,
      ...props.modalLeftStyle
    }
  } else {
    return style
  }

})

// 默认的right样式
const innerContentRightStyle = computed(() => {
  const style = {
    flex: 1
    // width: '251px',
  }

  if (props.modalRightStyle) {
    return {
      ...style,
      ...props.modalRightStyle
    }
  } else {
    return style
  }
})

// 默认的footer样式
const innerFooterStyle = computed(() => {
  const style = {
    'width': '454px',
  }

  if (props.footerStyle) {
    return {
      ...style,
      ...props.footerStyle
    }
  } else {
    return style
  }
})

const close = () => {
  visible.value = false;
}

defineExpose({ close })
const handleOk = () => {
  visible.value = false;
  emits('closeModal', false)
};


const handleConfirm = () => {

  handleOk();
  emits('confirm', false);
}



</script>

<style lang="less" scoped>
:global(.matting-container .ant-modal-body) {
  padding: 59px 26px 24px 36px;
  width: 100%;
}

:global(.matting-container .ant-modal-content) {
  border-radius: 4px;
}

:global(.matting-container .ant-modal-close) {
  cursor: default;
}

:global(.matting-container .ant-modal-close-x) {
  width: 24px;
  height: 24px;
  margin-right: 26px;
  margin-top: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all .5s;
  cursor: pointer;

}

:global(.matting-container .ant-modal-close-x:hover) {
  background: #eaeaea;
}

.matting-modal-container {


  .matting-container {
    z-index: 99999;

    .ant-modal-content {
      width: 840px;
    }

    .ant-modal-body {
      padding: 19.27px 28.27px 24px 36px !important;

    }
  }


  .close-icon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all .5s;

    // &:hover {
    //   background-color: #eaeaea;
    // }
  }

  .bg-replace-modal-container {
    height: 100%;
    width: 100%;
    display: flex;
    margin-top: 45.27px;
  }


  .justify-center {
    justify-content: center;
  }

  .footer-box {
    margin-top: 14px;
    display: flex;
    justify-content: center;

    .ant-btn {
      width: 130px;
      height: 42px;
      border: 1px solid #D0D0D0;
      border-radius: 200px;
      cursor: pointer;
    }

    .upload-btn {
      margin-right: 16px;
      width: 130px;
      height: 42px;
      background: #FFFFFF;
      border: 1px solid #D0D0D0;
      border-radius: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    button[ant-click-animating-without-extra-node]:after {
      border: 0 none;
      opacity: 0;
      color: rgba(0, 0, 0, 0.85);
      animation: none 0 ease 0 1 normal;
    }

    .upload-btn:hover {
      color: rgba(0, 0, 0, 0.85);
      background-color: #EDEDED !important;
      outline: none;
    }

    .upload-btn:focus {
      outline: none
    }
  }

  .modal-content-right {

    .images-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, 76px);
      grid-template-rows: 76px 76px;
      grid-gap: 12px 12px;
      margin-top: 18px;
    }

    .image-item {
      width: 76px;
      height: 76px;
      cursor: pointer;
    }

    .color-item {
      width: 76px;
      height: 76px;
      border-radius: 4.14545px;
      cursor: pointer;
    }

    img {
      border-radius: 4.14545px;
    }
  }




  #canvas-box {
    width: 454px;
    height: 454px;
    background-color: #EDEDED;
    display: flex;
    align-items: center;
    justify-content: center;

    #replace-bg-canvas {
      background-image: linear-gradient(45deg, #c5c5c5 25%, transparent 0),
        linear-gradient(45deg, transparent 75%, #c5c5c5 0),
        linear-gradient(45deg, #c5c5c5 25%, transparent 0),
        linear-gradient(45deg, transparent 75%, #c5c5c5 0);
      background-position: 0 0, 6px 6px, 6px 6px, 12px 12px;
      background-size: 12px 12px;
    }
  }

  .bg-title {
    font-family: 'Inter', Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}
</style>