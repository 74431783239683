import { _post } from "@/utils/request.js";
const preApi = process.env.NODE_ENV !== "production" ? "/api" : process.env.VUE_APP_API;
const URL = {
  cutout: preApi + "/ai/cutout",
  download: preApi + "/ai/image_enhancer/download",
  batch: preApi + "/ai/cutout/batch",
  asyncResult: preApi + "/task/task/cutout",
};

//单张高清化
const getObjetBackgroundRemover = async (data) => _post(URL.cutout, data);

//单张下载
const downloadFile = async (data) => _post(URL.download, data);

//高清化 批量上传
const getBatchTaskId = async (data) => _post(URL.batch, data);

//查询异步任务结果
const getAsyncResult = async (data) => _post(URL.asyncResult, data);

export { getObjetBackgroundRemover, downloadFile, getBatchTaskId, getAsyncResult };
