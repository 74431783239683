<template>
  <div>
    <bg-replace-modal v-bind="props" @closeModal="handleModalClose" :modalVisible="props.isShowReplaceBgModal"
      ref="bgReplaceModal" :footerStyle="footerStyle">
      <template v-slot:modalRight>
        <modal-content-right>
          <template v-slot:rightContent>
            <div class="right-content-container">
              <div v-if="!isGenerate" class="upload-btn-box">
                <div class="upload-btn custom-upload">
                  <a-upload-dragger v-model="bgFileList" name="file" :customRequest="customBgUpload" id="bgUpload"
                    @drop="handleFileDrop" :showUploadList="false" @change="(e) => handleReplaceBgChange(e, item)"
                    :accept="acceptFile" :beforeUpload="handleBeforeUpload">

                    <div class="ant-upload-text" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave"
                      :class="{ 'hoverStyle': isHover }"
                      style="margin-bottom: 0px; color: #875EFF; font-size: 14px;width:270px;height:64px;line-height: 64px; border-radius: 64px; border: 1px solid #875EFF;">
                      Upload </div>

                  </a-upload-dragger>
                </div>
                <div class="upload-btn-text">
                  <p> or</p>
                  <p>
                    Try AI-generated backgrounds that match image style
                  </p>
                </div>
                <custom-btn :customStyle="customStyle" :showPrefixIcon="true" @click="handleGenerate"
                  activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)">
                  AI Generate
                </custom-btn>
              </div>
              <div v-else class="bg-list-container">
                <div class="back-btn-hover">
                  <div class="back-btn" @click="handleBack">
                    <img :src="iconArrow" class="icon-left-arrow" /> back
                  </div>
                </div>
                <div class="flex btns-container">
                  <custom-btn btnWidth="126px" btnHeight="42px" borderRadius="4px 0 0 4px"
                    :customStyle="showBgType === 'image' ? activeBtnStyle : defaultBtnStyle"
                    @click="handleTabClick('image')">Images</custom-btn>
                  <custom-btn btnWidth="126px" btnHeight="42px" borderStyle="1px solid #D0D0D0"
                    borderRadius="0px 4px 4px 0" :customStyle="showBgType === 'color' ? activeBtnStyle : defaultBtnStyle"
                    @click="handleTabClick('color')">Color</custom-btn>
                </div>

                <div class="bg-wrap">
                  <ul class="bg-container" :style="bgContainer">
                    <image-item v-for="item in bgList" :key="item.id" :imageItem="item" :type="showBgType"
                      class="image-item-style" @click="() => handleSelectBg(e, item)" :imageWidth="props.imageWidth"
                      :currentShowBgItem="currentShowBgItem" boxWidth="130px" :showBoxBorder="true"
                      :loading="getBackgroundLoading" :border="item.border" borderRadius="4.14545px" />
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </modal-content-right>
      </template>
      <template v-slot:footer>
        <div class="footer-container" v-if="isGenerate">
          <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)"
            :customStyle="regenerateCustomStyle" :showPrefixIcon="true" @click="handleGenerate"
            v-if="showBgType === 'image'">
            AI Generate
          </custom-btn>
          <!-- <a-upload v-model:file-list="fileList" name="file" :multiple="false" :customRequest="customBgUpload"
            :showUploadList="false" @change="handleReplaceBgChange" :headers="headers"
            :beforeUpload="handleBeforeUpload" accept=".png,.jpg,.jpeg,.webp,.PNG,.JPG,.JPEG">
            <div type="default" class="upload-btn">Upload</div>
          </a-upload> -->
          <custom-btn activeColor="#ededed" :customStyle="defaultCustomStyle" :showPrefixIcon="false"
            @click="handleConfirm" activeStyle="#EDEDED">
            Apply
          </custom-btn>
        </div>
      </template>
    </bg-replace-modal>
  </div>
</template>
<script setup>
import { defineProps, ref, computed, reactive, defineEmits, watchEffect, watch, nextTick } from 'vue';
import BgReplaceModal from './bg-replace-modal.vue'
import ModalContentLeft from './modal-content-left.vue'
import modalContentRight from './modal-content-right.vue'
import ImageItem from './image-item.vue'
import { modalProps } from './type.js';
import { imagesBgsList, colorBgsList } from '../models/background-remover/data.js'
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import { renderCoverBgImageInCanvas } from '../matting-board/helpers/drawing-helper';
import { message } from "ant-design-vue";
import { useBackgroundDRemoveStore } from "@/store";
import { storeToRefs } from 'pinia'
import iconArrow from "@/assets/common-icons/icon_arrow.svg";
import { eventTracking } from '../../utils/eventTracking';
const opacityBg = require('@/assets/common-icons/icon_opacity.png');

const backgroundDRemoveStore = useBackgroundDRemoveStore()
const { backgroundList, getBackgroundLoading, currentPager } = storeToRefs(backgroundDRemoveStore)
const props = defineProps({

  ...modalProps,
  isObject: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits(['close', 'confirmReplaceBg'])
const cvsWidth = ref(null)
const cvsHeight = ref(null)
const imageCvsRef = ref(null)
const showBgType = ref('image')
const uploadError = ref(false);
const isGenerate = ref(false)
const bgFileList = ref([])
const bgReplaceModal = ref(null)
const currentShowBgItem = ref(null)
const inputS3Uri = ref('')
const getImageLib = ref(false)
const isHover = ref(false)
const opacityBgImage = ref({
  id: 999,
  type: 'image',
  opacity: true,
  url: opacityBg
})


const bgIconList = ref([
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  }, {
    id: 5,
  }, {
    id: 6,
  }, {
    id: 7,
  }, {
    id: 8,
  }, {
    id: 9,
  }, {
    id: 10,
  }
])

const defaultBtnStyle = reactive({
  background: '#fff',
  border: '1px solid #D0D0D0',
  color: '#060606',
})

const activeBtnStyle = reactive({
  background: '#262626',
  border: '1px solid #262626',
  color: '#ffff'

})

const customStyle = reactive({
  width: '270px',
  height: '64px',
  background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
  'border-radius': '60px',
  'margin-top': '10px'
})

const regenerateCustomStyle = reactive({
  width: '204px',
  height: '42px',
  background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
  'border-radius': '60px',
  "margin-right": '24px'
})
const defaultCustomStyle = reactive({
  width: '204px',
  height: '42px',
  background: '#FFFFFF',
  border: '1px solid #D0D0D0',
  'border-radius': '200px',
  color: '#000000'
})

const bgContainer = reactive({
  display: 'grid',
  'grid-template-columns': `repeat(${props.repeatNum},${props.imageWidth})`,
  'grid-template-rows': `repeat(${props.imageWidth},${props.imageWidth})`,
  'grid-row-gap': '24px',
  'grid-column-gap': '24px',
})

const bgWrapStyle = reactive({
  border: '1px dashed #D0D0D0',
  'border-top': 'none'
})

const footerStyle = ref({
  width: '100%',
  // 'margin-top': '17px',
  // 'margin-bottom': '24px'
})

const customBgUpload = () => {
  eventTracking('booltool_page_edit', { is_batch: true, edit_type: props.isObject ? 'object_upload_background' : 'upload_background' });
  console.log('上传背景')
}

// 背景图预加载
const preLoadImageList = (bgsList) => {
  bgsList?.forEach(item => {
    let img = new Image()
    img.src = item.url
    img.onload = () => {
      console.log('背景图预加载')
    }
  })
}

const bgList = computed(() => {
  const bgList = backgroundList.value.map((item, index) => {
    return {
      id: index + 1,
      type: 'image',
      url: item
    }
  })

  if (props.isEdit) {
    bgList.unshift(opacityBgImage.value)
    bgList.pop()
  }

  if (getBackgroundLoading.value && showBgType.value === 'image') {
    return bgIconList.value
  }
  preLoadImageList(bgList)
  return showBgType.value === 'image' ? bgList : colorBgsList
})

const handleReplaceBgChange = ({ fileList, file }) => {
  if (uploadError.value) {
    return;
  }
  bgFileList.value = fileList;
  const bgItem = {
    type: 'file',
    file: file.originFileObj
  };
  currentShowBgItem.value = bgItem;
  bgReplaceModal.value.close()
  emits('confirmReplaceBg', currentShowBgItem.value);
}

// const drawBgImage = (url) => {
//   if (!url) {
//     return;
//   }
//   loadImage(
//     url,
//     async (bgImage) => {
//       if ((bgImage).type === "error") {
//         return
//       }
//       const canvasBox = document.getElementById('canvas-box')
//       var validImageSize = {}
//       if (props.resultImageData.width < canvasBox.width && props.resultImageData.height < canvasBox.height) {
//         validImageSize = {
//           width: props.resultImageData.width,
//           height: props.resultImageData.height,
//         }
//       } else {
//         validImageSize = computeValidImageSize(
//           props.resultImageData,
//           { width: canvasBox.clientWidth, height: canvasBox.clientHeight },
//           { horizontal: 0, vertical: 0 }
//         );
//       }
//       cvsWidth.value = validImageSize.width;
//       cvsHeight.value = validImageSize.height;
//       if (bgImage) {
//         await updateBgImage(bgImage);
//       }
//       updateSrcImage(props.resultImageData)
//     },
//     {
//       cover: true,
//       crop: true,
//       canvas: true,
//       crossOrigin: "Anonymous",
//     }
//   );

// }

const handleTabClick = (type) => {
  showBgType.value = type
}

watch(() => props.uploadUri, () => {
  inputS3Uri.value = props.uploadUri

  if (!props.uploadUri) {
    return
  }

  if (getImageLib.value) {
    getImageLibFn()
  }
})

watch(getImageLib, () => {
  // 生成背景库
  if (!props.uploadUri || !getImageLib.value) {
    return
  }

  getImageLibFn()

  getImageLib.value = false
})

const getImageLibFn = () => {
  const params = {
    inputS3Uri: props.uploadUri,
    pageSize: 15,
    pager: currentPager.value,
  }

  backgroundDRemoveStore.getBackgroundList(params)
}

const handleGenerate = () => {
  eventTracking('booltool_page_edit', { is_batch: true, edit_type: props.isObject ? 'object_ai_background' : 'ai_background' });
  isGenerate.value = true
  getImageLib.value = true
  customStyle.width = '252px';
  customStyle.height = '43px';
  bgWrapStyle.border = 'none'
  currentShowBgItem.value = null
  backgroundDRemoveStore.getBackgroundLoading = true
  emits('aiGenerate', true)
}
const handleBeforeUpload = (file) => {
  const acceptType = [
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/png",
    "image/PNG",
    "image/JPEG",
    "image/JPG",
  ];
  const isJpgOrPng = acceptType.includes(file.type);
  message.config({
    top: "80%",
    duration: 3,
    icon: () => {
      return false;
    },
  });
  if (!isJpgOrPng) {
    message.error("Only JPG , PNG , JPEG and WEBP images are supported.");
    uploadError.value = true;
  }
};

watchEffect(async () => {
  if (props.currentBgItem) {
    currentShowBgItem.value = props.currentBgItem
  }
})

const handleMouseEnter = () => {
  isHover.value = true
}

const handleMouseLeave = () => {
  isHover.value = false
}
// const renderImageData = () => {
//   const mattingElement = document.getElementById(
//     "replace-bg-canvas"
//   );
//   const mattingCtx = mattingElement?.getContext("2d");
//   if (!mattingElement || !mattingCtx) {
//     return
//   }
//   if (props.currentBgItem) {
//     if (props.currentBgItem.url) {
//       var bgImage = new Image();//创建img元素
//       bgImage.src = props.currentBgItem.url //背景图

//       bgImage.onload = async function () {
//         clearCanvas()
//         const canvasBox = document.getElementById('canvas-box')

//         var validImageSize = {}
//         if (props.resultImageData.width < canvasBox.width && props.resultImageData.height < canvasBox.height) {
//           validImageSize = {
//             width: props.resultImageData.width,
//             height: props.resultImageData.height,
//           }
//         } else {
//           validImageSize = computeValidImageSize(
//             props.resultImageData,
//             { width: canvasBox.clientWidth, height: canvasBox.clientHeight },
//             { horizontal: 0, vertical: 0 }
//           );
//         }


//         cvsWidth.value = validImageSize.width;
//         cvsHeight.value = validImageSize.height;
//         if (bgImage) {
//           await updateBgImage(bgImage);
//           // await updateBgImage(props.bgData, bgValidImageSize);
//         }
//         updateSrcImage(props.resultImageData)

//       }
//     } else {
//       drawBgImage(props.currentBgItem)
//     }
//   } else {
//     const canvasBox = document.getElementById('canvas-box')

//     var validImageSize = {}
//     if (props.resultImageData.width < canvasBox.width && props.resultImageData.height < canvasBox.height) {
//       validImageSize = {
//         width: props.resultImageData.width,
//         height: props.resultImageData.height,
//       }
//     } else {
//       validImageSize = computeValidImageSize(
//         props.resultImageData,
//         { width: canvasBox.clientWidth, height: canvasBox.clientHeight },
//         { horizontal: 0, vertical: 0 }
//       );
//     }

//     cvsWidth.value = validImageSize.width;
//     cvsHeight.value = validImageSize.height;
//     updateSrcImage(props.resultImageData)
//   }

// }


async function updateSrcImage (srcData) {
  const imageCvs = imageCvsRef.value;
  const imageCtx = imageCvs.getContext('2d');
  const srcBitmap = await createImageBitmap(srcData);
  imageCtx.drawImage(srcBitmap, 0, 0, imageCtx.canvas.width, imageCtx.canvas.height);
}


async function updateBgImage (bgData) {
  const imageCvs = imageCvsRef.value;
  const imageCtx = imageCvs.getContext('2d');
  const bgBitmap = await createImageBitmap(bgData);
  renderCoverBgImageInCanvas(bgBitmap, imageCtx);
}

const handleSelectBg = async (e, item) => {
  if (item.type === 'color') {
    eventTracking('booltool_page_edit', { is_batch: true, edit_type: props.isObject ? 'object_color_background' : 'color_background' });
  }
  currentShowBgItem.value = item
}

const handleModalClose = () => {
  bgReplaceModal.value.close()
  getImageLib.value = false
  isGenerate.value = false
  showBgType.value = 'image'
  backgroundDRemoveStore.backgroundList = []
  backgroundDRemoveStore.getBackgroundLoading = false
  customStyle.width = '270px'
  customStyle.height = '64px'
  customStyle['border-radius'] = '60px'
  emits('close')
}

const handleConfirm = () => {
  bgReplaceModal.value.close()
  getImageLib.value = false
  isGenerate.value = false
  backgroundDRemoveStore.backgroundList = []
  backgroundDRemoveStore.getBackgroundLoading = false
  customStyle.width = '270px'
  customStyle.height = '64px'
  customStyle['border-radius'] = '60px'
  emits('confirmReplaceBg', currentShowBgItem.value);
}

const handleBack = () => {
  isGenerate.value = false
  customStyle.width = '270px'
  customStyle.height = '64px'
  customStyle['border-radius'] = '60px'
}

</script>
<style lang="less" scoped>
.bg-wrap {
  width: 100%;
  height: 413px;
  border-top: none;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  // justify-content: center;
  // align-items: center;
}

.tip-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #969696;
  padding: 116px 12px 28px 12px;


  p {
    text-align: center;
  }
}

.footer-container {
  // width: 454px;
  margin-top: 33px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;


  .ant-btn {
    width: 130px;
    height: 42px;
    border: 1px solid #D0D0D0;
    border-radius: 200px;
    cursor: pointer;
  }

  .upload-btn {
    margin-right: 16px;
    width: 130px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #875EFF;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;


  }

  button[ant-click-animating-without-extra-node]:after {
    border: 0 none;
    opacity: 0;
    color: rgba(0, 0, 0, 0.85);
    animation: none 0 ease 0 1 normal;
  }

  .upload-btn:hover {
    color: rgba(0, 0, 0, 0.85);
    background-color: #EDEDED !important;
    outline: none;
  }

  .upload-btn:focus {
    outline: none
  }
}

.bg-list-container {
  width: 100%;
  height: 100%;
  margin-top: 26px;
}

.btns-container {
  justify-content: center;
  margin-top: 22px;
  margin-bottom: 40px;
}

.right-content-container {
  // padding-right: 35.73px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-btn-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 173px;

  .upload-btn {
    width: 270px;
    height: 64px;
    border: 1px solid #875EFF;
    border-radius: 60px;
    text-align: center;
    color: #060606;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
    margin-bottom: 42px;

    :global(.ant-upload.ant-upload-drag .ant-upload-text:hover) {
      background-color: #F5F2FF !important;
    }
  }

  .upload-btn-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #969696;
    margin-bottom: 42px;
  }
}

.bg-wrap {
  height: 320px;
  overflow: scroll;
}

.box-scroll {
  overflow: scroll;
}

.back-btn-hover {
  width: 79px;
  height: 26px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: #EDEDED;

  }
}

.hoverStyle {
  background-color: #EDEDED;
}

.back-btn {
  cursor: pointer;
  display: flex;

  .icon-left-arrow {
    margin-right: 22.25px;
    // width: 13.5px;
    // height: 7.5px;
  }
}

.bg-container {
  width: 136px;
  height: 136px;

  .image-item-style {
    width: 136px;
    height: 136px;
  }
}


::-webkit-scrollbar {
  width: 7px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 50px;
  background: rgba(31, 35, 41, 0.3);
  // -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(31, 35, 41, 0.3);
}
</style>