import { _post, fileUpload } from "@/utils/request.js";
const preApi =
  process.env.NODE_ENV !== "production" ? "/api" : process.env.VUE_APP_API;
const URL = {
  uploadImage: preApi + "/file/image-upload", //上传单张图片
  uploadImages: preApi + "/file/images-upload", //批量上传
  uploadVideo: preApi + "/file/video-upload", //批量上传
};

const uploadImage = async (data) => _post(URL.uploadImage, data);

const uploadImages = async (data) => _post(URL.uploadImages, data);

const uploadImageSingle = async (data) => fileUpload(URL.uploadImage, data);

//upload video
const uploadVideoSingle = async (data) => fileUpload(URL.uploadVideo, data);

const batchUploadImage = async (data) => _post(URL.uploadImages, data);

export {
  uploadImage,
  uploadImages,
  uploadImageSingle,
  batchUploadImage,
  uploadVideoSingle,
};
