"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return (vue_2.pushScopeId("data-v-67546e55"), n = n(), vue_2.popScopeId(), n); };
var _hoisted_1 = { style: { "position": "relative" } };
var _hoisted_2 = ["width", "height"];
var vue_3 = require("vue");
var vue3_draggable_resizable_1 = require("vue3-draggable-resizable");
require("vue3-draggable-resizable/dist/Vue3DraggableResizable.css");
var use_matting_board_1 = require("../matting-board/composables/use-matting-board");
var dom_helper_1 = require("../matting-board/helpers/dom-helper");
var drawing_helper_1 = require("../matting-board/helpers/drawing-helper");
var eventTracking_js_1 = require("@/utils/eventTracking.js");
exports.default = vue_1.defineComponent({
    __name: 'bg-edit-board',
    props: {
        srcData: {
            type: ImageData
        },
        srcRect: {
            type: Object
        },
        bgData: {
            type: ImageData
        },
        isBatch: {
            type: Boolean,
            default: false,
        },
        isObject: {
            type: Boolean,
            default: false,
        }
    },
    emits: ['update:srcRect'],
    setup: function (__props, _a) {
        var _this = this;
        var emit = _a.emit;
        var props = __props;
        var initialized = vue_3.ref(false);
        var boardEle = vue_3.ref(null);
        var boardCvs = vue_3.ref(null);
        var boardCtx = vue_3.ref(null);
        var srcBitmap = vue_3.ref(null);
        var bgBitmap = vue_3.ref(null);
        var canvasW = vue_3.ref(0);
        var canvasH = vue_3.ref(0);
        var canvasScaleRatio = vue_3.ref(1);
        var resizerActive = vue_3.ref(true);
        var lockAspectRatio = vue_3.ref(false);
        var resizerX = vue_3.ref(0);
        var resizerY = vue_3.ref(0);
        var resizerW = vue_3.ref(0);
        var resizerH = vue_3.ref(0);
        var canvasDom = vue_3.ref(null);
        var resizerRect = vue_3.computed(function () {
            return {
                x: resizerX.value,
                y: resizerY.value,
                width: resizerW.value,
                height: resizerH.value
            };
        });
        var scaledResizerRect = vue_3.computed(function () {
            var scaleRatio = canvasScaleRatio.value;
            return {
                x: resizerX.value / scaleRatio,
                y: resizerY.value / scaleRatio,
                width: resizerW.value / scaleRatio,
                height: resizerH.value / scaleRatio
            };
        });
        var initBoardAndSize = function ($el, imageData) {
            var validImageSize;
            if (imageData.width < $el.clientWidth && imageData.height < $el.clientHeight) {
                validImageSize = {
                    width: imageData.width,
                    height: imageData.height,
                    imageScaleRatio: 1,
                };
            }
            else {
                validImageSize = use_matting_board_1.computeValidImageSize(imageData, { width: $el.clientWidth, height: $el.clientHeight }, { horizontal: 0, vertical: 0 });
            }
            canvasW.value = validImageSize.width;
            canvasH.value = validImageSize.height;
            canvasScaleRatio.value = validImageSize.imageScaleRatio;
            boardCtx.value = boardCvs.value.getContext('2d');
        };
        var initResizerRect = function (srcRect) {
            if (srcRect) {
                resizerX.value = srcRect.x * canvasScaleRatio.value;
                resizerY.value = srcRect.y * canvasScaleRatio.value;
                resizerW.value = srcRect.width * canvasScaleRatio.value;
                resizerH.value = srcRect.height * canvasScaleRatio.value;
            }
            else {
                resizerX.value = 0;
                resizerY.value = 0;
                resizerW.value = canvasW.value;
                resizerH.value = canvasH.value;
            }
        };
        var isDragged = function () {
            if (resizerX.value !== 0 || resizerY.value !== 0 || resizerW.value !== canvasW.value || resizerH.value !== canvasH.value) {
                return true;
            }
            return false;
        };
        vue_3.onMounted(function () {
            document.onkeydown = function (event) {
                if (event.keyCode == 16) {
                    lockAspectRatio.value = true;
                }
            };
            document.onkeyup = function (event) {
                if (event.keyCode == 16) {
                    lockAspectRatio.value = false;
                }
            };
        });
        vue_3.onUnmounted(function () {
            document.onkeydown = null;
            document.onkeyup = null;
            if (canvasDom.value) {
                canvasDom.value.onmouseup = null;
            }
        });
        var onResizerWidthChange = function (value) {
            if (value) {
                resizerW.value = value;
            }
        };
        var onResizerHeightChange = function (value) {
            if (value) {
                resizerH.value = value;
            }
        };
        var drawSrcImage = function (imageCtx, srcBitmap, resizerRect) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (srcBitmap) {
                    imageCtx.drawImage(srcBitmap, resizerRect.x * 2, resizerRect.y * 2, resizerRect.width * 2, resizerRect.height * 2);
                    console.log('origin', 0, 0, imageCtx.canvas.width, imageCtx.canvas.height);
                    console.log('result', resizerRect.x * 2, resizerRect.y * 2, resizerRect.width * 2, resizerRect.height * 2);
                }
                return [2];
            });
        }); };
        var drawBgImage = function (imageCtx, bgBitmap) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (bgBitmap) {
                    drawing_helper_1.renderCoverBgImageInCanvas(bgBitmap, imageCtx);
                }
                return [2];
            });
        }); };
        vue_3.onMounted(function () {
            vue_3.watchEffect(function () {
                if (props.srcData && !initialized.value) {
                    initBoardAndSize(boardEle.value, props.srcData);
                    initResizerRect(props.srcRect);
                    initialized.value = true;
                }
                if (initialized.value && canvasDom.value) {
                    canvasDom.value.onmouseup = function () {
                        if (isDragged()) {
                            eventTracking_js_1.eventTracking('booltool_page_edit', { is_batch: props.isBatch, edit_type: props.isObject ? 'item_object_drag' : 'object_drag' });
                        }
                    };
                }
            });
        });
        vue_3.watchEffect(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!((srcBitmap.value || bgBitmap.value) && resizerRect.value)) return [3, 3];
                        dom_helper_1.clearCanvas(boardCtx.value);
                        return [4, drawBgImage(boardCtx.value, bgBitmap.value)];
                    case 1:
                        _a.sent();
                        return [4, drawSrcImage(boardCtx.value, srcBitmap.value, resizerRect.value)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2];
                }
            });
        }); });
        vue_3.watchEffect(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!initialized.value || !props.srcData) {
                            srcBitmap.value = null;
                            return [2];
                        }
                        _a = srcBitmap;
                        return [4, createImageBitmap(props.srcData)];
                    case 1:
                        _a.value = _b.sent();
                        return [2];
                }
            });
        }); });
        vue_3.watchEffect(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!initialized.value || !props.bgData) {
                            bgBitmap.value = null;
                            return [2];
                        }
                        _a = bgBitmap;
                        return [4, createImageBitmap(props.bgData)];
                    case 1:
                        _a.value = _b.sent();
                        return [2];
                }
            });
        }); });
        vue_3.watch(scaledResizerRect, function (value) {
            emit('update:srcRect', value);
        });
        return function (_ctx, _cache) {
            return (vue_2.openBlock(), vue_2.createElementBlock("div", {
                class: "bg-edit-board",
                ref_key: "boardEle",
                ref: boardEle
            }, [
                vue_2.createElementVNode("div", _hoisted_1, [
                    vue_2.createElementVNode("canvas", {
                        class: "board-canvas",
                        ref_key: "boardCvs",
                        ref: boardCvs,
                        width: canvasW.value * 2,
                        height: canvasH.value * 2,
                        style: vue_2.normalizeStyle({ width: canvasW.value + 'px', height: canvasH.value + 'px' })
                    }, null, 12, _hoisted_2),
                    (initialized.value)
                        ? (vue_2.openBlock(), vue_2.createElementBlock("div", {
                            key: 0,
                            class: "resizer-box",
                            ref_key: "canvasDom",
                            ref: canvasDom
                        }, [
                            vue_2.createVNode(vue_2.unref(vue3_draggable_resizable_1.default), {
                                classNameDraggable: "dragger",
                                active: resizerActive.value,
                                lockAspectRatio: lockAspectRatio.value,
                                minW: 100,
                                minH: 100,
                                x: resizerX.value,
                                "onUpdate:x": _cache[0] || (_cache[0] = function ($event) { return ((resizerX).value = $event); }),
                                y: resizerY.value,
                                "onUpdate:y": _cache[1] || (_cache[1] = function ($event) { return ((resizerY).value = $event); }),
                                w: resizerW.value,
                                h: resizerH.value,
                                "onUpdate:w": onResizerWidthChange,
                                "onUpdate:h": onResizerHeightChange
                            }, null, 8, ["active", "lockAspectRatio", "x", "y", "w", "h"])
                        ], 512))
                        : vue_2.createCommentVNode("", true)
                ])
            ], 512));
        };
    }
});
