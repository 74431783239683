export const modalProps = {
  footerStyle: Object,
  modalLeftStyle: Object,
  modalRightStyle: Object,
  isShowReplaceBgModal: {
    type: Boolean,
    default: false
  },
  resultImageData: Object,
  resultImageRect: Object,
  defaultBgImages: Object,
  currentBgItem: Object,
  showCanvasBox: {
    type: Boolean,
    default: true
  },
  cvsWidth: {
    type: Number,
    default: 454
  },
  cvsHeight: {
    type: Number,
    default: 454
  },

  bgItemStyle: {

  },
  imageItem: Object,
  repeatNum: {
    type: Number,
    default: 3
  },
  imageWidth: {
    type: String,
    default: '76px'
  },
  imageHeight: {
    type: String,
    default: '76px'
  },
  customStyle: Object,
  type: String,
  borderRadius: String,
  modalVisible: {
    type: Boolean,
    default: false
  },
  isShowBitchReplaceBgModal: {
    type: Boolean,
    default: false
  },

  uploadUri: {
    type: String,
    default: ''
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  isBatch: {
    type: Boolean,
    default: false
  },
  toolType: {
    type: String,
    default: 'remove-background'
  }
}