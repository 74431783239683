"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return (vue_2.pushScopeId("data-v-2a58890c"), n = n(), vue_2.popScopeId(), n); };
var _hoisted_1 = { key: 0 };
var _hoisted_2 = {
    id: "canvas-box",
    style: { width: '454px', height: '454px' }
};
var _hoisted_3 = { class: "right-content-container" };
var _hoisted_4 = { class: "flex btns-container" };
var _hoisted_5 = {
    key: 1,
    class: "tip-text"
};
var _hoisted_6 = _withScopeId(function () { return vue_2.createElementVNode("p", { class: "tip-text-bold" }, "No favorite background image on hand?", -1); });
var _hoisted_7 = _withScopeId(function () { return vue_2.createElementVNode("p", null, "Let AI generate artistic backgrounds that match your image style.", -1); });
var _hoisted_8 = [
    _hoisted_6,
    _hoisted_7
];
var _hoisted_9 = { class: "footer-container" };
var _hoisted_10 = _withScopeId(function () { return vue_2.createElementVNode("div", {
    type: "default",
    class: "upload-btn"
}, "Upload", -1); });
var vue_3 = require("vue");
var ant_design_vue_1 = require("ant-design-vue");
var pinia_1 = require("pinia");
var image_item_1 = require("./image-item");
var bg_edit_board_1 = require("./bg-edit-board");
var bg_replace_modal_1 = require("./bg-replace-modal");
var modal_content_left_1 = require("./modal-content-left");
var modal_content_right_1 = require("./modal-content-right");
var custom_btn_1 = require("@/components/boolv-ui/custom-btn");
var store_1 = require("@/store");
var type_js_1 = require("./type.js");
var data_js_1 = require("@/components/models/background-remover/data.js");
var util_js_1 = require("@/utils/util.js");
var eventTracking_js_1 = require("@/utils/eventTracking.js");
exports.default = vue_1.defineComponent({
    __name: 'index',
    props: __assign(__assign({}, type_js_1.modalProps), { isObject: {
            type: Boolean,
            default: false,
        } }),
    emits: ['close', 'confirm'],
    setup: function (__props, _a) {
        var _this = this;
        var emits = _a.emit;
        var props = __props;
        var opacityBg = require('@/assets/common-icons/icon_opacity.png');
        var backgroundDRemoveStore = store_1.useBackgroundDRemoveStore();
        var _b = pinia_1.storeToRefs(backgroundDRemoveStore), backgroundList = _b.backgroundList, getBackgroundLoading = _b.getBackgroundLoading, currentPager = _b.currentPager;
        var showBgType = vue_3.ref('image');
        var uploadError = vue_3.ref(false);
        var isGenerate = vue_3.ref(false);
        var bgFileList = vue_3.ref([]);
        var bgReplaceModalRef = vue_3.ref(null);
        var inputS3Uri = vue_3.ref('');
        var getImageLib = vue_3.ref(false);
        var opacityBgItem = {
            id: 999,
            type: 'image',
            opacity: true,
            url: opacityBg
        };
        var bgIconList = vue_3.ref([
            {
                id: 1,
            },
            {
                id: 2,
            },
            {
                id: 3
            },
            {
                id: 4,
            }, {
                id: 5,
            }, {
                id: 6
            }, {
                id: 7
            }, {
                id: 8
            }, {
                id: 9
            }, {
                id: 10
            }, {
                id: 11
            }, {
                id: 12
            }
        ]);
        var selectedBgItem = vue_3.ref(null);
        var srcImageData = vue_3.ref(null);
        var bgImageData = vue_3.ref(null);
        var srcImageRect = vue_3.ref(props.resultImageRect);
        vue_3.onMounted(function () {
            console.log('onMounted');
        });
        var preLoadImageList = function (bgsList) {
            bgsList === null || bgsList === void 0 ? void 0 : bgsList.forEach(function (item) {
                var img = new Image();
                img.src = item.url;
                img.onload = function () {
                    console.log('背景图预加载');
                };
            });
        };
        var bgList = vue_3.computed(function () {
            var bgList = backgroundList.value.map(function (item, index) {
                return {
                    id: index + 1,
                    type: 'image',
                    url: item
                };
            });
            if (props.isEdit) {
                bgList.unshift(opacityBgItem);
                bgList.pop();
            }
            if (getBackgroundLoading.value && showBgType.value === 'image') {
                return bgIconList.value;
            }
            preLoadImageList(bgList);
            return showBgType.value === 'image' ? bgList : data_js_1.colorBgsList;
        });
        var defaultBtnStyle = vue_3.reactive({
            background: '#fff',
            border: '1px solid #D0D0D0',
            color: '#060606',
        });
        var activeBtnStyle = vue_3.reactive({
            background: '#262626',
            border: '1px solid #262626',
            color: '#ffff'
        });
        var customStyle = vue_3.reactive({
            width: '184px',
            height: '42px',
            background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
            'border-radius': '60px',
            'margin-top': '10px'
        });
        var bgContainer = vue_3.reactive({
            display: 'grid',
            'grid-template-columns': "repeat(" + props.repeatNum + "," + props.imageWidth + ")",
            'grid-template-rows': "repeat(" + props.imageWidth + "," + props.imageWidth + ")",
            'grid-row-gap': '11px',
            'grid-column-gap': '12px',
            'padding-top': '20px'
        });
        var bgWrapStyle = vue_3.reactive({
            border: '1px dashed #D0D0D0',
            'border-top': 'none'
        });
        var handleTabClick = function (type) {
            showBgType.value = type;
        };
        vue_3.watch(function () { return props.uploadUri; }, function () {
            inputS3Uri.value = props.uploadUri;
            if (!props.uploadUri) {
                return;
            }
            if (getImageLib.value) {
                getImageLibFn();
            }
        });
        vue_3.watch(getImageLib, function () {
            if (!props.uploadUri || !getImageLib.value) {
                return;
            }
            getImageLibFn();
            getImageLib.value = false;
        });
        var getImageLibFn = function () {
            var params = {
                inputS3Uri: props.uploadUri,
                pageSize: 12,
                pager: currentPager.value,
            };
            backgroundDRemoveStore.getBackgroundList(params);
            getImageLib.value = false;
        };
        var dataAndGenerate = function () {
            eventTracking_js_1.eventTracking('booltool_page_edit', { is_batch: props.isBatch, edit_type: props.isObject ? 'object_ai_background' : 'ai_background' });
            handleGenerate();
        };
        var handleGenerate = function () {
            customStyle.width = '252px';
            customStyle.height = '43px';
            bgWrapStyle.border = 'none';
            isGenerate.value = true;
            getImageLib.value = true;
            backgroundDRemoveStore.getBackgroundLoading = true;
        };
        var handleBeforeUpload = function (file) {
            var acceptType = [
                "image/jpeg",
                "image/jpg",
                "image/webp",
                "image/png",
                "image/PNG",
                "image/JPEG",
                "image/JPG",
            ];
            var isJpgOrPng = acceptType.includes(file.type);
            if (!isJpgOrPng) {
                ant_design_vue_1.message.error({
                    content: "Only JPG , PNG , JPEG and WEBP images are supported.",
                    duration: 3,
                    icon: function () {
                        return false;
                    },
                });
                uploadError.value = true;
            }
        };
        var handleUploadRequest = function () {
            eventTracking_js_1.eventTracking('booltool_page_edit', { is_batch: props.isBatch, edit_type: props.isObject ? 'object_upload_background' : 'upload_background' });
            console.log('上传背景');
        };
        var handleFinishUpload = function (_a) {
            var file = _a.file, fileList = _a.fileList;
            return __awaiter(_this, void 0, void 0, function () {
                var bgItem;
                return __generator(this, function (_b) {
                    if (uploadError.value) {
                        return [2];
                    }
                    bgFileList.value = fileList;
                    bgItem = {
                        type: 'file',
                        file: file.originFileObj
                    };
                    handleSelectBg(bgItem);
                    return [2];
                });
            });
        };
        var handleModalClose = function () {
            isGenerate.value = false;
            getImageLib.value = false;
            srcImageRect.value = props.resultImageRect;
            showBgType.value = 'image';
            backgroundDRemoveStore.backgroundList = [];
            backgroundDRemoveStore.getBackgroundLoading = false;
            bgReplaceModalRef.value.close();
            emits('close');
        };
        var handleConfirm = function () {
            isGenerate.value = false;
            getImageLib.value = false;
            backgroundDRemoveStore.backgroundList = [];
            backgroundDRemoveStore.getBackgroundLoading = false;
            bgReplaceModalRef.value.close();
            var editData = {
                imageRect: srcImageRect.value,
                bgItem: selectedBgItem.value,
            };
            emits('confirm', editData);
        };
        var handleSelectBg = function (item) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (item.type === 'color') {
                    eventTracking_js_1.eventTracking('booltool_page_edit', { is_batch: props.isBatch, edit_type: props.isObject ? 'object_color_background' : 'color_background' });
                }
                selectedBgItem.value = item;
                return [2];
            });
        }); };
        var selectColorBg = function (item) { return __awaiter(_this, void 0, void 0, function () {
            var _a, width, height, context;
            return __generator(this, function (_b) {
                _a = srcImageData.value, width = _a.width, height = _a.height;
                context = util_js_1.default.createCanvasCtx({ width: width, height: height });
                context.rect(0, 0, width, height);
                context.fillStyle = item.color;
                context.fillRect(0, 0, width, height);
                bgImageData.value = context.getImageData(0, 0, width, height);
                return [2];
            });
        }); };
        var selectImgBg = function (item) { return __awaiter(_this, void 0, void 0, function () {
            var img, bitmap, context;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        img = new Image();
                        img.crossOrigin = 'anonymous';
                        img.src = item.file ? URL.createObjectURL(item.file) : item.url;
                        return [4, new Promise(function (resolve) {
                                img.onload = function () { return resolve(); };
                            })];
                    case 1:
                        _a.sent();
                        return [4, createImageBitmap(img)];
                    case 2:
                        bitmap = _a.sent();
                        context = util_js_1.default.createCanvasCtx(bitmap);
                        context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
                        bgImageData.value = context.getImageData(0, 0, bitmap.width, bitmap.height);
                        return [2];
                }
            });
        }); };
        vue_3.watchEffect(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                srcImageData.value = props.resultImageData;
                selectedBgItem.value = props.currentBgItem;
                return [2];
            });
        }); });
        vue_3.watch(function () { return props.resultImageData; }, function () {
            handleGenerate();
        });
        vue_3.watch(function () { return props.resultImageRect; }, function () {
            srcImageRect.value = props.resultImageRect;
        }, {
            deep: true,
            immediate: true
        });
        vue_3.watch(selectedBgItem, function (item) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!item || item.opacity) {
                    bgImageData.value = null;
                    return [2];
                }
                if (item.color) {
                    selectColorBg(item);
                    return [2];
                }
                if (item.url || item.file) {
                    selectImgBg(item);
                    return [2];
                }
                console.warn('不支持的背景: ', item);
                return [2];
            });
        }); });
        return function (_ctx, _cache) {
            var _component_a_upload = vue_2.resolveComponent("a-upload");
            return (props.isShowReplaceBgModal)
                ? (vue_2.openBlock(), vue_2.createElementBlock("div", _hoisted_1, [
                    vue_2.createVNode(vue_2.unref(bg_replace_modal_1.default), vue_2.mergeProps(props, {
                        onCloseModal: handleModalClose,
                        modalVisible: props.isShowReplaceBgModal,
                        ref_key: "bgReplaceModalRef",
                        ref: bgReplaceModalRef,
                        isBatch: props.isBatch,
                        isObject: props.isObject
                    }), {
                        modalLeft: vue_2.withCtx(function () { return [
                            vue_2.createVNode(vue_2.unref(modal_content_left_1.default), null, {
                                contentLeft: vue_2.withCtx(function () { return [
                                    vue_2.createElementVNode("div", _hoisted_2, [
                                        (!!srcImageData.value)
                                            ? (vue_2.openBlock(), vue_2.createBlock(vue_2.unref(bg_edit_board_1.default), {
                                                key: 0,
                                                srcData: srcImageData.value,
                                                bgData: bgImageData.value,
                                                srcRect: srcImageRect.value,
                                                "onUpdate:srcRect": _cache[0] || (_cache[0] = function ($event) { return ((srcImageRect).value = $event); }),
                                                isBatch: props.isBatch,
                                                isObject: props.isObject
                                            }, null, 8, ["srcData", "bgData", "srcRect", "isBatch", "isObject"]))
                                            : vue_2.createCommentVNode("", true)
                                    ])
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        modalRight: vue_2.withCtx(function () { return [
                            vue_2.createVNode(vue_2.unref(modal_content_right_1.default), null, {
                                rightContent: vue_2.withCtx(function () { return [
                                    vue_2.createElementVNode("div", _hoisted_3, [
                                        vue_2.createElementVNode("div", _hoisted_4, [
                                            vue_2.createVNode(vue_2.unref(custom_btn_1.default), {
                                                btnWidth: "126px",
                                                btnHeight: "42px",
                                                borderRadius: "4px 0 0 4px",
                                                customStyle: showBgType.value === 'image' ? activeBtnStyle : defaultBtnStyle,
                                                onClick: _cache[1] || (_cache[1] = function ($event) { return (handleTabClick('image')); })
                                            }, {
                                                default: vue_2.withCtx(function () { return [
                                                    vue_2.createTextVNode("Images")
                                                ]; }),
                                                _: 1
                                            }, 8, ["customStyle"]),
                                            vue_2.createVNode(vue_2.unref(custom_btn_1.default), {
                                                btnWidth: "126px",
                                                btnHeight: "42px",
                                                borderStyle: "1px solid #D0D0D0",
                                                borderRadius: "0px 4px 4px 0",
                                                customStyle: showBgType.value === 'color' ? activeBtnStyle : defaultBtnStyle,
                                                onClick: _cache[2] || (_cache[2] = function ($event) { return (handleTabClick('color')); })
                                            }, {
                                                default: vue_2.withCtx(function () { return [
                                                    vue_2.createTextVNode("Color")
                                                ]; }),
                                                _: 1
                                            }, 8, ["customStyle"])
                                        ]),
                                        vue_2.createElementVNode("div", {
                                            class: "bg-wrap",
                                            style: vue_2.normalizeStyle(showBgType.value === 'image' && bgWrapStyle)
                                        }, [
                                            (isGenerate.value || showBgType.value === 'color')
                                                ? (vue_2.openBlock(), vue_2.createElementBlock("ul", {
                                                    key: 0,
                                                    class: "bg-container",
                                                    style: vue_2.normalizeStyle(bgContainer)
                                                }, [
                                                    (vue_2.openBlock(true), vue_2.createElementBlock(vue_2.Fragment, null, vue_2.renderList(vue_2.unref(bgList), function (item) {
                                                        return (vue_2.openBlock(), vue_2.createBlock(vue_2.unref(image_item_1.default), {
                                                            key: item.id,
                                                            imageItem: item,
                                                            type: showBgType.value,
                                                            class: "image-item-style",
                                                            onClick: function ($event) { return (handleSelectBg(item)); },
                                                            loading: vue_2.unref(getBackgroundLoading),
                                                            border: item.border,
                                                            borderRadius: "4.14545px"
                                                        }, null, 8, ["imageItem", "type", "onClick", "loading", "border"]));
                                                    }), 128))
                                                ], 4))
                                                : vue_2.createCommentVNode("", true),
                                            (showBgType.value === 'image' && !isGenerate.value)
                                                ? (vue_2.openBlock(), vue_2.createElementBlock("div", _hoisted_5, _hoisted_8))
                                                : vue_2.createCommentVNode("", true),
                                            (showBgType.value === 'image')
                                                ? (vue_2.openBlock(), vue_2.createBlock(vue_2.unref(custom_btn_1.default), {
                                                    key: 2,
                                                    activeColor: "linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)",
                                                    showPrefixIcon: true,
                                                    customStyle: customStyle,
                                                    onClick: dataAndGenerate
                                                }, {
                                                    default: vue_2.withCtx(function () { return [
                                                        vue_2.createTextVNode(" AI Generate ")
                                                    ]; }),
                                                    _: 1
                                                }, 8, ["customStyle"]))
                                                : vue_2.createCommentVNode("", true)
                                        ], 4)
                                    ])
                                ]; }),
                                _: 1
                            })
                        ]; }),
                        footer: vue_2.withCtx(function () { return [
                            vue_2.createElementVNode("div", _hoisted_9, [
                                vue_2.createVNode(_component_a_upload, {
                                    name: "file",
                                    accept: ".png,.jpg,.jpeg,.webp,.PNG,.JPG,.JPEG",
                                    multiple: false,
                                    showUploadList: false,
                                    "file-list": bgFileList.value,
                                    "onUpdate:file-list": _cache[3] || (_cache[3] = function ($event) { return ((bgFileList).value = $event); }),
                                    customRequest: handleUploadRequest,
                                    beforeUpload: handleBeforeUpload,
                                    onChange: handleFinishUpload
                                }, {
                                    default: vue_2.withCtx(function () { return [
                                        _hoisted_10
                                    ]; }),
                                    _: 1
                                }, 8, ["file-list"]),
                                vue_2.createElementVNode("div", {
                                    type: "default",
                                    class: "upload-btn",
                                    onClick: handleConfirm
                                }, "Done")
                            ])
                        ]; }),
                        _: 1
                    }, 16, ["modalVisible", "isBatch", "isObject"])
                ]))
                : vue_2.createCommentVNode("", true);
        };
    }
});
