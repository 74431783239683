import { saveAs } from "file-saver";
import JSZip from "jszip";
import * as md5 from "md5";
import { renderCoverBgImageInCanvas } from "@/components/matting-board/helpers/drawing-helper";

export const saveImgZip = (imgUrlList, toolName) => {
  // var imgUrlArr = []; // 图base64列表
  // imgUrlArr = imgUrlList
  // var imgBase64 = {};//存放转成base64的图片，采用键值对形式就行存储，不采用数组。因为数组在异步中会导致存取数据的无序，数据混乱
  // var imgNameArr = [];  // 图片名称
  // var imageSuffix = []; // 图片后缀

  // for (var i = 0; i < imgUrlList.length; i++) {
  //   // 图片后缀
  //   var suffix = imgUrlList[i].substring(imgUrlList[i].lastIndexOf("."));
  //   imageSuffix.push(suffix);
  //   //获取文件的名称
  //   var startIndex = imgUrlList[i].lastIndexOf("/");
  //   var endIndex = imgUrlList[i].lastIndexOf(".");
  //   var fileName = imgUrlList[i].substring(startIndex + 1, endIndex);
  //   imgNameArr.push(fileName);
  // }

  // 创建JSZip对象
  var zip = new JSZip();
  // 创建文件夹
  var img = zip.folder("images");

  // 读取图片返回base64
  // for (var i = 0; i < imgUrlArr.length; i++) {
  //   getBase64(imgUrlArr[i], i).then(function (base64, index) {
  //     //imgBase64.push(base64.substring(22));  // 去掉base64的图片格式前缀
  //     imgBase64[index] = base64.substring(22);
  //   }, function (err) {
  //     console.log(err);
  //   });
  // }

  function downloadZip() {
    setTimeout(function () {
      imgUrlList.forEach((element, index) => {
        // console.log("element=======", element);
        const basePic = element.replace(/^data:image\/(png|jpg);base64,/, "");
        img.file(toolName + "-" + index + 1 + ".png", basePic, {
          base64: true,
        });
      });
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, `${toolName}-${md5(Date.now())}.zip`);
      });
    }, 100);
  }

  downloadZip();
};

//图片转base64; 传入图片路径，返回base64
export const transformBase64 = async (item) => {
  const imageData = item.removeBgResult;
  const imageRect = item.imageRect;
  const canvas = document.createElement("canvas");
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext("2d");
  const resultBitmap = await createImageBitmap(item.removeBgResult);
  if (item.bgImageData) {
    const bgBitmap = await createImageBitmap(item.bgImageData);
    renderCoverBgImageInCanvas(bgBitmap, ctx);
  }
  if (imageRect) {
    ctx.drawImage(
      resultBitmap,
      imageRect.x,
      imageRect.y,
      imageRect.width,
      imageRect.height
    );
  } else {
    ctx.drawImage(resultBitmap, 0, 0);
  }
  return canvas.toDataURL("image/png");
};

export function compressByImagesUrl(imgUrls, zipName) {
  // Create a new JSZip instance
  const zip = new JSZip();
  console.log(imgUrls);
  // Add images to the zip
  let s = [];
  let len = imgUrls.length;
  imgUrls.forEach(async (imgUrl) => {
    // Fetch image data and add it to the zip
    if (!imgUrl) {
      len--;
    }
    const response = await fetch(imgUrl);
    const imgData = await response.blob();
    zip.file(imgUrl.split("/").pop(), imgData);
    s.push(imgUrl.split("/").pop());
    if (s.length === len) {
      console.log(s);
      // Generate the zip file and download it
      zip.generateAsync({ type: "blob" }).then((content) => {
        // see FileSaver.js
        console.log(content);
        saveAs(content, zipName);
      });
    }
  });
}
