<template>
  <div class="back-ground-remover-wrap">
    <!-- 顶部操作区域 start-->
    <div class="top-container">
      <div class="back-btn-container">
        <back-btn @back="handleBack" />
      </div>
    </div>

    <!-- 顶部操作区域 end-->
    <!-- 图片展示区域 start-->
    <div class="image-show-container" :style="{ marginTop: minClient ? '24px' : '49px' }">
      <!-- 单图 -->
      <div v-if="innerImageList && innerImageList.length === 1">
        <div class="flex" v-for="(item, index) in innerImageList" :key="index">
          <div class="flex-left">
            <div class="result-container container" :style="{ width: currentContainerSize.width }">
              <h3 class="result-title" :style="{ marginBottom: minClient ? '10px' : '24px' }">Removed Background</h3>
              <div class="position-box" @mouseenter="(e) => handleMattingItemBoxMouseEnter(e, item, index)"
                @mouseleave="() => handleMattingItemBoxMouseLeave(e, item, index)"
                :style="{ width: currentContainerSize.width }">
                <div class="result-item-box">
                  <div class="image-box result-image-box"
                    :style="{ width: currentContainerSize.width, height: currentContainerSize.height }">
                    <div class="icon-check-original-box" @mousedown="handleCheckOriginalMousedown"
                      @mouseup="handleCheckOriginalMouseup" @mouseenter="handleCheckOriginalMouseenter"
                      @mouseleave="handleCheckOriginalMouseLeave">
                      <a-tooltip placement="bottom" :visible="tipVisible">
                        <template #title>
                          <span>Original</span>
                        </template>
                        <img :src="iconCheckOriginal" />
                      </a-tooltip>
                    </div>
                    <image-board ref="resultBoardRef" :srcData="item.removeBgResult" :isModelDone="isModelDone"
                      v-if="item.removeBgResult && !showOriginal" :currentContainerSize="currentContainerSize.width" />
                    <image-board ref="resultBoardRef" :srcData="item.originImageData" :isModelDone="isModelDone"
                      v-else-if="item.originImageData && showOriginal"
                      :currentContainerSize="currentContainerSize.width" />
                    <image-board ref="resultBoardRef" :srcData="item.originImageData" :isModelDone="isModelDone" v-else
                      :currentContainerSize="currentContainerSize.width" />
                  </div>
                  <div class="buttons-container buttons-container-justify" v-if="currentEditItem?.id === item.id && item.showBtns === true
                    ">
                    <custom-btn activeColor="linear-gradient(289deg, #EDEDED 18.5%, #EDEDED 99.95%)" :customStyle="{
                        width: '164px',
                        height: '36px',
                        background: '#fff',
                        'border-radius': '100px',
                        color: '#060606',
                        'margin-right': '12px',
                        'margin-bottom': '16px',
                      }" @click="(e) => handleItemEditRemove(e, item)">Edit
                    </custom-btn>
                    <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)" :customStyle="{
                        width: '164px',
                        height: '36px',
                        background:
                          'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
                        'border-radius': '100px',
                        color: '#FFFFFF',
                        'margin-bottom': '16px',

                      }" @click="(e) => handleMattingDownload(e, item)">Download
                    </custom-btn>
                  </div>
                </div>
                <div class="loading-mask-box" v-if="!isLoaded"></div>
              </div>
              <common-rate @changeRate="handleMattingChangeRate" :clearRate="clearRate" ref="rate1" />
            </div>
          </div>
          <div class="flex-right">
            <div class="add-container container">
              <h3 class="result-title" :style="{ marginBottom: minClient ? '10px' : '24px' }">Add Background</h3>
              <div class="image-box add-design-container position-box" id="upload-container-draggle"
                :class="{ 'add-design-container-hover': isUploadBg }"
                :style="{ width: currentContainerSize.width, height: currentContainerSize.height }"
                v-if="!showAddDesignResult" @drop="handleUploadBoxDrop">
                <div class="upload-box" id="upload-box-draggle" @mouseenter="handleUploadBg"
                  @mouseleave="handleCancelUploadBg">
                  <a-upload-dragger v-model="bgFileList" name="file" :customRequest="customBgUpload" id="bgUpload"
                    @drop="handleFileDrop" :showUploadList="false" @change="(e) => handleBgUploadFinish(e, item)"
                    :accept="acceptFile" :beforeUpload="handleBeforeUpload">
                    <p class="ant-upload-text" :class="{ 'ant-upload-text-color': isUploadBg }">
                      {{ uploadBgPlaceholder || "Upload" }}
                    </p>
                  </a-upload-dragger>
                </div>
                <div class="add-design-tip">
                  <p>or</p>
                  <p>Try AI-generated backgrounds that match image style</p>
                </div>
                <div class="bg-items">
                  <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)"
                    :customStyle="customStyle" @click="(e) => handleItemChangeBg(e, item)" :showPrefixIcon="true">
                    AI Generate
                  </custom-btn>
                </div>
                <div class="loading-mask-box" v-if="!isLoaded"></div>
              </div>

              <div class="position-box" @mouseenter="handleReplaceBgBoxMouseEnter"
                @mouseleave="handleReplaceBgBoxMouseLeave">
                <div v-show="showAddDesignResult" class="image-box"
                  :style="{ width: currentContainerSize.width, height: currentContainerSize.height }">
                  <image-board ref="bgBoardRef" :srcData="item.removeBgResult" :bgData="item.bgImageData"
                    :imageRect="item.imageRect" :showOpacityBg="false" :isModelDone="isModelDone"
                    :currentContainerSize="currentContainerSize.width" />
                </div>
                <div class="buttons-container buttons-container-justify" v-if="showEditReplaceBgBtns">
                  <custom-btn activeColor="linear-gradient(289deg, #fff 18.5%, #fff 99.95%)" :customStyle="{
                      width: '164px',
                      height: '36px',
                      background: '#fff',
                      'border-radius': '100px',
                      color: '#060606',
                      'margin-right': '12px',
                      'margin-bottom': '16px',
                    }" @click="(e) => handleItemChangeBg(e, item, true)">Edit
                  </custom-btn>
                  <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)" :customStyle="{
                      width: '164px',
                      height: '36px',
                      background:
                        'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
                      'border-radius': '100px',
                      color: '#FFFFFF',
                      'margin-bottom': '16px',
                    }" @click="(e) => handleBgReplaceDownload(e, item)">Download
                  </custom-btn>
                </div>
              </div>


              <common-rate @changeRate="handleReplaceBgChangeRate" v-if="item.bgImageData" :clearRate="clearRate"
                ref="rate" />

            </div>
          </div>
        </div>
      </div>

      <div class="spin-container" v-else>
        <a-spin />
      </div>
    </div>
    <!-- 图片展示区域 end-->
    <!-- 擦除编辑弹窗 -->
    <matting-edit-modal :isShowMattingModal="isShowMattingModal" :rawImage="rawImage" :resultImage="resultImage"
      @closeModal="handleMattingModalClose" @savaMattingImage="handleSavaMattingImage" :isObject="true" />

    <!-- 替换背景弹窗 -->
    <bg-replace-modal ref="bg-replace-modal" :isShowReplaceBgModal="isShowReplaceBgModal"
      :resultImageData="currentEditData.srcImageData" :resultImageRect="currentEditData.srcImageRect"
      :currentBgItem="currentEditData.bgItem" @close="handleCloseReplaceBgModal" @confirm="handleBgReplaceConfirm"
      :uploadUri="inputS3Uri" :isEdit="isEdit" :isObject="true" />

  </div>
</template>

<script setup>
import {
  onMounted,
  ref,
  watch,
  defineProps,
  reactive,
  defineEmits,
  onUnmounted,
  computed,
  nextTick
} from "vue";
import { message } from "ant-design-vue";
import MattingEditModal from "@/components/matting-edit-modal";
import BgReplaceModal from "@/components/bg-replace-modal/index.vue";
import ImageBoard from "@/components/image-board";
import CommonRate from "@/components/common-rate.vue";
import BackBtn from "@/components/boolv-ui/back-btn";
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";

import loadImage from "blueimp-load-image";
import dataUtils from "@/utils/util.js";
import { uploadImageSingle } from '@/api/upload/upload.js'
import { storeToRefs } from 'pinia'
import {
  MessageLoading,
  destroy,
  MessageSuccess
} from "@/components/boolv-ui/Message/index.js";
import { eventTracking, postCanvasToUrl } from "@/utils/eventTracking.js";
import { renderCoverBgImageInCanvas } from "../../matting-board/helpers/drawing-helper";
import { getObjetBackgroundRemover } from "@/api/models/object-background-remover.js";
import iconCheckOriginal from '@/assets/common-icons/icon_check_original.svg'
import { useSideStore, useViewStore } from '@/store/index.js'
const sideStore = useSideStore()
const viewStore = useViewStore()
const { minClient } = storeToRefs(sideStore)

const emits = defineEmits(['back'])
var md5 = require("md5");

const props = defineProps({
  modelFilepath: String,
  imageSize: Object,
  imageUrls: Array,
  warmupModel: Function,
  preProcess: Function,
  postProcess: Function,
  resizeSize: Object,
  imageList: Array,
  uploadSuccessImages: Array,
});

// 图片上传列表
const innerImageList = ref([]);
const imageLoadingError = ref(false);
const currentFileList = ref([]);
const bgFileList = ref([]);
const showAddDesignResult = ref(false);
const rate = ref(null);
const rate1 = ref(null);
const rawImage = ref(null);
const resultImage = ref(null);
const resultBoardRef = ref(null);
const isShowMattingModal = ref(false);
const isShowReplaceBgModal = ref(false);
const showEditReplaceBgBtns = ref(false);
const currentReplaceBgSelectRate = ref({});
const inputS3Uri = ref('')
const isEdit = ref(false)
const isLoaded = ref(false)

const currentEditData = reactive({
  srcImageData: null,
  srcImageRect: null,
  bgItem: null
});

const clearRate = ref(false);
const isModelDone = ref(false);
const uploadError = ref(false);
const isUploadBg = ref(false);
const isBatchAddBackground = ref(false);
const isBatchDownload = ref(false);
const isHandleNum = ref(0);
const currentEditItem = ref(null);
const showOriginal = ref(false);

const tipVisible = ref(false)
const customStyle = reactive({
  width: "270px",
  height: "64px",
  background: "linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)",
  "border-radius": "60px",
});

const currentContainerSize = computed(() => {
  let size = null
  if (sideStore.minClient) {
    size = {
      width: '522px',
      height: '522px'
    }
  } else {
    size = {
      width: '560px',
      height: '560px'
    }
  }

  // imageBordSize.value = size

  return size
})

const handleBeforeUpload = (file) => {
  console.log(file);
  const acceptType = [
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/png",
    "image/PNG",
    "image/JPEG",
    "image/JPG",
  ];
  const isJpgOrPng = acceptType.includes(file.type);
  message.config({
    top: "80%",
    duration: 3,
    icon: () => {
      return false;
    },
  });
  if (!isJpgOrPng) {
    message.error("Only JPG , PNG , JPEG and WEBP images are supported.");
    uploadError.value = true;
    return;
  }
};

onMounted(async () => {
  // 加载模型，并初始化
  // viewStore.setMinViewWidth('1400px')
  MessageLoading("Processing, it will take a while", 0, "", null, false, cancelRequest);
  sideStore.showGlobalMask = true;
  const uploadContainer = document.getElementById("upload-container-draggle");
  uploadContainer.addEventListener("dragover", (e) => {
    e.preventDefault();
    isUploadBg.value = true;
  });

  uploadContainer.addEventListener("drop", (e) => {
    e.preventDefault();
    const fileList = [
      {
        originFileObj: e.dataTransfer.files[0],
      },
    ];
    handleBgUploadFinish({ fileList });
  });

  uploadContainer.addEventListener("dragenter", () => {
    isUploadBg.value = true;
  });

  uploadContainer.addEventListener("dragleave", () => {
    isUploadBg.value = false;
  });
});

const cancelRequest = () => {
  destroy()
  if (window.axiosPromiseArr) {
    window.axiosPromiseArr.forEach((ele, ind) => {
      ele.cancel()
      delete window.axiosPromiseArr[ind]
    })
  }
}

const handleFileDrop = (e) => {
  uploadError.value = false;
  const file = e.dataTransfer.files[0];
  sideStore.isCollapse = true;
  const acceptType = [
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/png",
    "image/PNG",
    "image/JPEG",
    "image/JPG",
  ];
  const isJpgOrPng = acceptType.includes(file.type);
  message.config({
    top: "80%",
    duration: 3,
    icon: () => {
      return false;
    },
  });
  if (!isJpgOrPng) {
    message.error("Only JPG , PNG , JPEG and WEBP images are supported.");
    uploadError.value = true;
  }
};

// 监听处理进度，提前上传图片
watch(isHandleNum, () => {
  if (isHandleNum.value === innerImageList.value.length) {
    isLoaded.value = true
    sideStore.showGlobalMask = false
    destroy()
    MessageSuccess("Processing completed");
  }
})

// canvas 生成图片
const getCanvasFromImage = (imageData) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  ctx.putImageData(imageData, 0, 0);
  return canvas;
};

const transformUrlToImage = (file) => {
  let imageData = null
  return new Promise((resolve, reject) => {

    loadImage(
      file,
      async (img) => {
        if (img.type === "error") {
          reject()
          return
        }
        const tempCtx = dataUtils.createCanvasCtx(img);
        tempCtx.drawImage(img, 0, 0);
        imageData = await tempCtx.getImageData(0, 0, img.width, img.height);
        resolve(imageData)
      },
      {
        cover: true,
        crop: true,
        canvas: true,
        crossOrigin: "Anonymous",
      }
    );
  })
}

const handleBackgroundRemove = async (data, item) => {
  const params = {
    inputS3Uri: data.url,
    renderId: 1,
  };

  try {
    const { code, data } = await getObjetBackgroundRemover(params);
    if (code === 0) {
      inputS3Uri.value = data.outputS3Uri
      const result = await transformUrlToImage(data.outputUrl)
      item.removeBgResult = result
      isHandleNum.value += 1
    }
  } catch (error) {
    destroy()
    sideStore.showGlobalMask = false;
    console.log(error);
  }
}

watch(() => props.imageList, (newVal) => {
  if (newVal && newVal.length) {
    innerImageList.value = props.imageList;
    innerImageList.value.forEach(async (item, index) => {
      const canvas = getCanvasFromImage(item.originImageData);
      const name = `object-background-remover-${md5(Date.now())}`
      const file = dataUtils.transformFile(canvas, `${name}.png`);
      item.fileName = name
      await nextTick()

      try {
        const { data } = await uploadImageSingle({
          key: name,
          file: file
        })

        handleBackgroundRemove(data, item)
      } catch (err) {
        console.log('err', err)
      }



    })
  }
}, {
  immediate: true
});


// 背景替换
const handleBgUploadFinish = (value, item) => {
  if (uploadError.value) {
    return;
  }
  currentEditItem.value = item;
  bgFileList.value = value.fileList;
  const file = value.fileList[0].originFileObj;

  const bgItem = {
    type: 'file',
    file: file
  };
  showAddDesignResult.value = true;
  updateBgImageAndRect(item, bgItem, null);
};

const updateBgImageAndRect = async (targetItem, bgItem, imageRect) => {
  const bgImageData = await getBgItemImageData(targetItem.removeBgResult, bgItem);
  innerImageList.value.forEach((item) => {
    if (item.id === targetItem.id) {
      item.bgImageData = bgImageData;
      item.bgImageItem = bgItem;
      item.imageRect = imageRect;
    }
  });
}

const getBgItemImageData = async (targetSize, bgItem) => {
  if (!bgItem || bgItem.opacity) {
    return null;
  }

  if (bgItem.color) {
    const context = dataUtils.createCanvasCtx(targetSize);
    context.rect(0, 0, targetSize.width, targetSize.height);
    context.fillStyle = bgItem.color;
    context.fillRect(0, 0, targetSize.width, targetSize.height);
    return context.getImageData(0, 0, targetSize.width, targetSize.height);
  }

  if (bgItem.url || bgItem.file) {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = bgItem.file ? URL.createObjectURL(bgItem.file) : bgItem.url;
    await new Promise((resolve) => {
      img.onload = () => resolve();
    });
    const bitmap = await createImageBitmap(img);
    const context = dataUtils.createCanvasCtx(bitmap);
    context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
    return context.getImageData(0, 0, bitmap.width, bitmap.height);
  }
}

// 背景上传
const customBgUpload = () => {
  eventTracking('booltool_page_edit', { is_batch: false, edit_type: 'upload_background' });
  console.log("上传背景");
};

// 关闭擦除弹窗
const handleMattingModalClose = () => {
  isShowMattingModal.value = false;
};

const handleSavaMattingImage = (imageData) => {
  resultImage.value = imageData;
  currentEditItem.value.removeBgResult = imageData;
};

// 关闭背景替换弹窗
const handleCloseReplaceBgModal = () => {
  isShowReplaceBgModal.value = false;
};

// 按钮显隐
const handleReplaceBgBoxMouseEnter = () => {
  showEditReplaceBgBtns.value = true;
};

const handleReplaceBgBoxMouseLeave = () => {
  showEditReplaceBgBtns.value = false;
};
// 上报rate
const emoji = ['star_struck', 'face_blowing_a_kiss', 'thinking_face', 'face_vomiting'];
const handleMattingChangeRate = (rate) => {
  if (rate) {
    eventTracking('booltool_page_feedback', { tool_name: 'object_bg_remover', emoji_str: emoji[rate.id - 1] });
  }
}
const handleReplaceBgChangeRate = (rate) => {
  currentReplaceBgSelectRate.value = rate;
  if (rate) {
    eventTracking('booltool_page_feedback', { tool_name: 'object_change_bg', emoji_str: emoji[rate.id - 1] });
  }
};

// 确认背景替换
const handleBgReplaceConfirm = async ({ imageRect, bgItem }) => {

  isShowReplaceBgModal.value = false;
  showAddDesignResult.value = true;
  await updateBgImageAndRect(currentEditItem.value, bgItem, imageRect);
  // // 替换背景埋点上报
  // if (bgItem.type === 'color') {
  //   eventTracking("booltool_page_edit", { tool_name: "object_color_background", is_batch: true, is_success: true, is_ai_background: false })
  // } else if (bgItem.type === 'file') {
  //   eventTracking("booltool_page_edit", { tool_name: "object_upload_background", is_batch: true, is_success: true, is_ai_background: false })
  // }
};
//当前图片埋点记录
const storeCurrentImage = async () => {
  const isSource = false;
  const container = document.getElementsByClassName('add-container')[0];
  const domList = container.getElementsByTagName('canvas');
  console.log(domList);
  await postCanvasToUrl(domList, isSource);
}
// 替换背景下载
const handleBgReplaceDownload = async (e, item) => {
  const imageData = item.removeBgResult;
  const imageRect = item.imageRect;
  const canvas = document.createElement("canvas");
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext("2d");
  if (item.bgImageData) {
    const bgBitmap = await createImageBitmap(item.bgImageData);
    renderCoverBgImageInCanvas(bgBitmap, ctx);
  }
  const resultBitmap = await createImageBitmap(item.removeBgResult);
  if (imageRect) {
    ctx.drawImage(resultBitmap, imageRect.x, imageRect.y, imageRect.width, imageRect.height);
  } else {
    ctx.drawImage(resultBitmap, 0, 0);
  }

  dataUtils.downloadFn(canvas, `add-background-${md5(Date.now())}`)
  //upload data download
  let upload_cnt = 0;
  let ai_cnt = 0;
  let color_cnt = 0;
  let nobg_cnt = 1;
  const calcBgType = (item) => {
    if (item.bgImageItem?.type === 'file') {
      upload_cnt++;
      nobg_cnt--;
    }
    if (item.bgImageItem?.type === 'image') {
      ai_cnt++;
      nobg_cnt--;
    }
    if (item.bgImageItem?.type === 'color') {
      color_cnt++;
      nobg_cnt--;
    }
  }
  calcBgType(innerImageList.value[0]);
  await storeCurrentImage();
  eventTracking("booltool_page_download", { tool_name: "object_bg_remover", is_batch: false, is_success: true, is_ai_background: true, ai_background_cnt: ai_cnt, uploaded_background_cnt: upload_cnt, color_background_cnt: color_cnt, no_background_cnt: nobg_cnt });
};

// 抠图下载: 前端打包下载
const handleMattingDownload = (e, item) => {
  const canvas = getCanvasFromImage(item.removeBgResult);
  eventTracking("booltool_page_download", { tool_name: "object_bg_remover", is_batch: false, is_success: true, is_ai_background: false, ai_background_cnt: 0, uploaded_background_cnt: 0, color_background_cnt: 0, no_background_cnt: 1 });
  dataUtils.downloadFn(canvas, `add-background-${md5(Date.now())}`);
};



// 清除画布
const clearCanvas = () => {
  imageLoadingError.value = false;
  showAddDesignResult.value = false;
  currentFileList.value = [];
  resultImage.value = null;
  rawImage.value = null;
};

const handleUploadBoxDrop = (e) => {
  console.log("handleUploadBoxDrop", e);
};

// hover显示操作按钮
const handleMattingItemBoxMouseEnter = (e, item) => {
  if (isHandleNum.value < innerImageList.value.length) {
    return;
  }
  if (
    !item.removeBgResult ||
    isBatchAddBackground.value ||
    isBatchDownload.value
  ) {
    return;
  }
  currentEditItem.value = item;
  currentEditItem.value.showBtns = true;
};

const handleMattingItemBoxMouseLeave = (e, item) => {
  currentEditItem.value.showBtns = false;
};

// 编辑按钮，显示抠图编辑弹窗
const handleItemEditRemove = (e, item) => {
  currentEditItem.value = item;
  isShowMattingModal.value = true;
  rawImage.value = item.originImageData;
  resultImage.value = item.removeBgResult;
};

// 修改背景, 打开背景修改弹窗
const handleItemChangeBg = (e, item, mode) => {
  isEdit.value = mode
  isShowReplaceBgModal.value = true;
  currentEditItem.value = item;
  // 给 BgReplaceModal 的数据
  currentEditData.srcImageData = item.removeBgResult;
  currentEditData.srcImageRect = item.imageRect;
  currentEditData.bgItem = item.bgImageItem;

}

const handleCheckOriginalMousedown = () => {
  showOriginal.value = true;
  tipVisible.value = false
};

const handleCheckOriginalMouseenter = () => {
  tipVisible.value = true
}

const handleCheckOriginalMouseLeave = () => {
  tipVisible.value = false
}

// 查看原图
const handleCheckOriginalMouseup = () => {
  showOriginal.value = false;
  tipVisible.value = true
};

const handleBack = () => {
  emits("back");
};

onUnmounted(() => {
  destroy()
  sideStore.showGlobalMask = false;
})
</script>

<style lang="less" scoped>
.back-ground-remover-wrap {
  min-height: calc(100vh - 60px);
  min-width: 1400px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
}

.top-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.image-show-container {}

.flex-left {
  // width: 560px;
  // padding: 61px 0px;
  margin-right: 60px;
  display: flex;
  align-content: flex-end;
}

.flex-left-title {
  color: #000000;
  padding-bottom: 15px;
  text-align: left;
}

.flex-right {
  padding: 0px 0px 37.63px 0px;
  // width: 560px;
}

.backGroundRemovers-container {
  // padding: 0 32px;
  width: 100%;
  min-height: calc(100vh - 60px);
  display: grid;
}

.container {
  // margin-right: 30px;
}

.result-item-box {
  position: relative;
}

.result-container {
  // width: 560px;
  height: 100%;
}

.loading-box {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 300ms ease;
  border-radius: 8px;
  background: linear-gradient(180deg,
      transparent 62%,
      rgba(0, 0, 0, 0.00345888) 63.94%,
      rgba(0, 0, 0, 0.014204) 65.89%,
      rgba(0, 0, 0, 0.0326639) 67.83%,
      rgba(0, 0, 0, 0.0589645) 69.78%,
      rgba(0, 0, 0, 0.0927099) 71.72%,
      rgba(0, 0, 0, 0.132754) 73.67%,
      rgba(0, 0, 0, 0.177076) 75.61%,
      rgba(0, 0, 0, 0.222924) 77.56%,
      rgba(0, 0, 0, 0.267246) 79.5%,
      rgba(0, 0, 0, 0.30729) 81.44%,
      rgba(0, 0, 0, 0.341035) 83.39%,
      rgba(0, 0, 0, 0.367336) 85.33%,
      rgba(0, 0, 0, 0.385796) 87.28%,
      rgba(0, 0, 0, 0.396541) 89.22%,
      rgba(0, 0, 0, 0.4) 91.17%);
}

.add-container {
  margin-right: 0;
}

.tool-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
}

.result-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 24px;
}

.position-box {
  position: relative;
  z-index: 1;
  // width: 560px;

  :global(.ant-tooltip-inner) {
    border-radius: 4px;
    color: #fff;
  }

  .icon-check-original-box {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;

    :global(.ant-tooltip-content) {
      width: fit-content !important;
      height: 36px;
    }

    :global(.ant-tooltip-content .ant-tooltip-inner) {
      width: fit-content !important;
      height: 36px;
    }
  }

  .loading-mask-box {
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    opacity: 0.5;
    position: absolute;
    left: 0;
    top: 0;
    text-align: right;
  }

  .icon-checkbox {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
  }

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    font-family: 'Inter', Arial;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: #875eff;
    }
  }

  .buttons-container-justify {
    display: flex;
    justify-content: center;
    padding: 0 80px 14px 80px;
  }

  .buttons-container-padding {
    padding: 0 154px 30px 154px;
  }

  .buttons-container-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 18px;
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.image-box {
  // width: 560px;
  // height: 560px;
  background-color: #f7f7f7;
  position: relative;
}

.result-image-box {
  position: relative;

  .canvas-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.draw-canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.webcam-panel {
  padding: 40px 20px;
  margin-top: 30px;
  background-color: white;
  position: relative;
}

.inputs {
  margin: auto;
  background: #f5f5f5;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  align-items: center;
  border-radius: 2px;
  display: inline-flex;
  width: 100%;
  height: 38px;
  font-size: 14px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  justify-content: center;
  padding: 0 16px;
}

.inputs:focus,
.inputs:hover {
  position: relative;
  background: rgba(0, 0, 0, 0.12);
}

.backend-selector {
  width: 200px;
}

.upload-box {
  width: 270px;
  height: 64px;
  border-radius: 60px;
}

.add-design-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 154px 100px;
}

.add-design-container-hover {
  background-color: #ececec;

  :global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text-color) {
    background-color: #ececec;
  }
}

:global(.add-design-container .ant-upload.ant-upload-drag .ant-upload) {
  width: 270px;
  height: 64px;
  border: 1px solid #875eff;
  border-radius: 60px;
  background: #f7f7f7;
  padding: 0;
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;

  line-height: 20px;
  text-align: center;
  color: #875eff;
  cursor: pointer;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  height: 100%;
  font-size: 14px;
  line-height: 64px;
  color: #875eff;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text:hover) {
  background-color: #F5F2FF !important;
}

:global(.add-design-container .ant-upload.ant-upload-drag) {
  border: none;
}

.add-design-tip {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #969696;
  margin-top: 42px;

  P {
    margin-bottom: 0;
  }
}

.bg-items {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 42px;
}

.bg-item {
  width: 76px;
  height: 76px;
  background: #d9d9d9;
  border-radius: 4.14545px;
  cursor: pointer;
}

:global(.unreal-dragger.ant-upload.ant-upload-drag) {
  // width: 560px;
  // height: 560px;
  // border: none;
  // background: none;
}

:global(.add-design-container .ant-upload.ant-upload-drag p.ant-upload-text) {
  margin: 0;
  border-radius: 60px;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover .ant-upload-text) {
  padding: 0;
  // background-color: rgba(135, 94, 255, 0.12);
  background-color: #ececec;
}

:global(.add-design-container .ant-upload.ant-upload-drag-hover) {
  background-color: rgba(135, 94, 255, 0.12);
}

:global(.upload-box .ant-upload.ant-upload-drag .ant-upload-text:hover) {
  background-color: #f7f7f7;
}

.back-btn-container {
  padding-top: 25px;
}

.btn-container {
  width: 100%;
  display: flex;
  padding: 16px 0 36px 0;
  justify-content: flex-end;
  align-items: center;
}

.btn-position {
  width: 100%;
  position: fixed;
  bottom: 68px;
  z-index: 99;
}

.select-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 42px;
}

.spin-container {
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-suffixIcon {
  width: 40px;
  height: 24px;
  margin-left: 12px;
}
</style>
